<template>
  <div class="process-manage">
      <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'processManage',
  data () {
    return {}
  }
}
</script>
<style lang="less" scoped>
.process-manage {
}
</style>
